html,
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

html {
  overflow-y: scroll;
}

.shake {
  animation: shakeAnimation 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shakeAnimation {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Bahnschrift";
  src: local("Bahnschrift"), url(./fonts/bahnschrift.ttf) format("truetype");
}

@font-face {
  font-family: "Arial Rounded MT Bold";
  src: local("Arial Rounded MT Bold"),
    url(./fonts/arialroundbold.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu Medium";
  src: local("Ubuntu Medium"), url(./fonts/Ubuntu-M.ttf) format("truetype");
}

@font-face {
  font-family: "Ubuntu Regular";
  src: local("Ubuntu Regular"), url(./fonts/Ubuntu-R.ttf) format("truetype");
}

@font-face {
  font-family: "Roboto-Regular";
  src: local("Roboto-Regular"),
    url(./fonts/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Bold";
  src: local("Lato-Bold"), url(./fonts/Lato-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "Lato-Regular";
  src: local("Lato-Regular"), url(./fonts/Lato-Regular.ttf) format("truetype");
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bahnschrift";
  font-weight: lighter;
}

p {
  font-family: "Ubuntu Regular";
}

h4 {
  font-size: 17px;
}

::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #3182bd;
}
.react-calendar {
  min-width: 100%;
  border-radius: 15px;
  background-color: #d7ecff;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  padding: 10px 15px 15px 15px;
  border-bottom: 3px solid #d7ecff;
  min-height: 45vh;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 2px;
  margin-top: 8px;
  width: 80%;
  height: 80%;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  display: flex;
  height: 3em;
  width: 100%;
  background-color: #d7ecff;
  border-radius: 6px;
  margin-bottom: 10px;
}
.react-calendar__navigation button {
  width: 100%;
  font-size: 16px;
  background: none;
}
.react-calendar__navigation button:disabled {
  background-color: #569fd3;
  border-radius: 6px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #76baff;
  border-radius: 6px;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
  margin-bottom: 10px;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
  margin: 5px;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
  border-radius: 6px;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
  border-radius: 6px;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 10px;
}
.react-calendar__tile {
  padding: 10px;
  background: none;
  text-align: center;
  line-height: 16px;
  font-size: 16px;
  border-radius: 6px;
}
.react-calendar__tile:disabled {
  background-color: #569fd3;
  border-radius: 6px;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #569fd3;
}
.react-calendar__tile--now {
  background: #ffff76;
  border-radius: 6px;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
  border-radius: 6px;
}
.react-calendar__tile--hasActive {
  background: #76baff;
  border-radius: 6px;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  border-radius: 6px;
}
.react-calendar__tile--active {
  background: #3182bd;
  color: white;
  border-radius: 6px;
  font-size: 16px;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #3182bd;
  border-radius: 6px;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #000;
  border-radius: 6px;
}

.highlightEvent {
  background-color: #fa9fb5;
  border-radius: 25px;
  color: white;
}

.highlightImportant {
  background-color: #fdbb84;
  border-radius: 25px;
  color: white;
}

.highlightHoliday {
  background-color: red;
  color: white;
  border-radius: 25px;
}

.highlightAssignment {
  background-color: #6a6fff;
  color: white;
  border-radius: 25px;
}

.highlightExam {
  background-color: #a1d99b;
  color: white;
  border-radius: 25px;
}

.highlightSaturday {
  color: black;
}

.react-calendar__month-view__days {
  display: grid !important;
  grid-template-columns: 14.4% 14.4% 14.4% 14.4% 14.4% 14.4% 14.4%;
  justify-content: center;
  margin-left: 4px;
}

.react-calendar__year-view__months,
.react-calendar__decade-view__years {
  display: grid !important;
  grid-template-columns: 25% 25% 25% 25%;
  justify-content: center;
  margin-left: 12px;
}

.react-calendar__century-view__decades {
  display: grid !important;
  grid-template-columns: 60% 60%;
  margin-left: 30px;
  justify-content: center;
}

.react-calendar__year-view__months {
  display: flex;
  grid-template-columns: 40% 40% 40%;
  justify-content: center;
  margin-left: 20px;
}

.buttonRipple {
  background-color: red;
}
